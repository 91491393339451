import React, { useContext, useEffect, useState } from 'react';
import { GwFlowStepProps, GwFlowStepResult } from "../GwFlow";
import TitleText from '../../common/TitleText';
import StepContent from '../../common/StepContent';
import { useTranslation } from 'react-i18next';

import { DebugContext } from '../../common/Debug';
import GwService from '../../services/GwService';
import Space from '../../common/Space';
import { Spin } from 'antd';
import {GwFlowResultOptions} from 'gw-api/types'
export default GwFlowResult;

export type GwFlowResultResult = GwFlowStepResult & {
    [key:string] : any;
};

export interface GwFlowResultProps extends Omit<GwFlowStepProps, 'options'> {
    options?: GwFlowResultOptions;
}

function GwFlowResult({
    onResult,
    onError,
    options,
    currentResult,
}: Partial<GwFlowResultProps>) {
    const { t } = useTranslation();
    const { logError, log } = useContext(DebugContext);
    const [loading, setLoading] = useState<boolean>(false);
    const sendResult = async () => {
        try {
            log('Sending results');
            setLoading(true);
            const response = await GwService.sendResult({});
            log('Result sent');
            onResult?.(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            onError?.(error);
        }
    };
    useEffect(() => {
        (async () => {
            await sendResult();
        })();
    }, []);

    return (
        <div className="GwFlowResult">
            <StepContent
                header={<TitleText size="large">{t('Finalizando')}</TitleText>}
                body={
                    <div>
                        <Space wide={true} direction="vertical">
                            {loading ? <Spin spinning={true} /> : null}
                            {t(
                                'Por favor espera mientras finalizamos el proceso'
                            )}
                        </Space>
                    </div>
                }
            />
        </div>
    );
}
