import React, { useEffect, useState } from 'react';
import { Collapse, Modal } from 'antd';
import { debugDataWithModal } from './DebugComponentsPage';
import { GwFlowStepResult } from '../components/GwFlow';
import { ConfigProvider } from '../components/ConfigContext';
import currentResultForValidate from '../mock/currentResultForValidate';
import GwService from '../services/GwService';
import GwFlowResult, {
    GwFlowResultProps,
} from '../components/GwFlowResult/GwFlowResult';

export default DebugGwFlowResult;
export type DebugGwFlowResultProps = {};

function DebugGwFlowResult({ ...rest }: DebugGwFlowResultProps) {
    const [ready, setReady] = useState<boolean>(false);
    useEffect(() => {
        (async () => {})();
    }, []);
    const onResult = (result: GwFlowStepResult) => {
        const { rawScanResult, ...rest } = result;
        debugDataWithModal('', {
            ...rest,
        });
    };
    const samples: {
        title: string;
        props: GwFlowResultProps;
    }[] = [
        {
            title: 'Default',
            props: {
                options: {},
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: currentResultForValidate,
            },
        },
    ];
    return (
        <Collapse destroyInactivePanel={true}>
            {samples.map(({ title, props }, index) => {
                return (
                    <Collapse.Panel header={title} key={`fragment-${index}`}>
                        <ConfigProvider
                            defaultTransactionId={
                                '9907e586-539d-4f4b-bbc6-579a24517000'
                            }
                            defaultClientId={25}
                            onLoad={async () => {
                                try {
                                    await GwService.update({
                                        result: currentResultForValidate,
                                        index: 0,
                                    });
                                    setReady(true);
                                } catch (e) {
                                    console.error(e);
                                }
                            }}
                        >
                            {ready ? <GwFlowResult {...props} /> : null}
                        </ConfigProvider>
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    );
}
