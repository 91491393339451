import React from 'react';
import { Collapse, Modal } from 'antd';
import { debugDataWithModal } from './DebugComponentsPage';
import { GwFlowStepResult } from '../components/GwFlow';
import GwFlowUploads, {
    GwFlowUploadsProps,
} from '../components/GwFlowUploads/GwFlowUploads';
import GwFlowWelcome, {
    GwFlowWelcomeProps,
} from '../components/GwFlowWelcome/GwFlowWelcome';

export default DebugGwFlowWelcome;
export type DebugGwFlowWelcomeProps = {};

function DebugGwFlowWelcome({ ...rest }: DebugGwFlowWelcomeProps) {
    const onResult = (result: GwFlowStepResult) => {
        const { rawScanResult, ...rest } = result;
        debugDataWithModal('', {
            ...rest,
        });
    };
    const samples: {
        title: string;
        props: GwFlowWelcomeProps;
    }[] = [
        {
            title: 'Default',
            props: {
                options: {},
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
            },
        },
    ];
    return (
        <Collapse destroyInactivePanel={true}>
            {samples.map(({ title, props }, index) => {
                return (
                    <Collapse.Panel header={title} key={`fragment-${index}`}>
                        <GwFlowWelcome {...props} />
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    );
}
