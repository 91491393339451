import request from './request';

export type GwServiceRequestHeaders = { [key: string]: string };
export type GwServiceRequestData = { [key: string]: any };

interface LoginRequestData {
    clientId: string;
    pass: string;
}

interface LoginResponseData {
    status: string;
    redirectTo: string;
}

interface SendResultRequestData {
    disableSendResult?: boolean;
    error?: any;
}

interface GwLivenessRequestData {
    imageBuffer: string;
}
export interface GwLivenessResonseDataResult {
    serviceResultCode: number;
    serviceResultLog: 'Live' | any;
    serviceTime: string;
    serviceTransactionId: string;
    serviceLivenessResult: number;
}
export interface GwLivenessResonseData {
    status: string;
    result: GwLivenessResonseDataResult;
}

interface TrackRequestData {
    geoLocation?: any;
}
interface SendOtpEmailRequestData {
    recipient: string;
}
interface ValidateOtpEmailRequestData {
    recipient: string;
    otp: string;
}

let gwApiUrl: string = '';
let gwHeaders: GwServiceRequestHeaders = {};
let gwStage = '';
let gwTransactionId = '';

export default class GwService {
    static addr() {
        return gwApiUrl;
    }

    static setAddr(addr: string) {
        gwApiUrl = addr;
    }
    static stage() {
        return gwStage ? `${gwStage}/` : '';
    }

    static setStage(stage: string) {
        gwStage = stage;
    }

    static headers() {
        return gwHeaders;
    }

    static setTransactionId(transactionId: string) {
        gwTransactionId = transactionId;
        gwHeaders['transaction-id'] = transactionId;
    }

    static setClientId(clientId: number) {
        gwHeaders['client-id'] = `${clientId}`;
    }

    static setTokenApp(tokenApp: string) {
        gwHeaders['token-app'] = tokenApp;
    }

    static getEventsUrl(transactionId) {
        return `${this.addr()}/events?id=${transactionId}`;
    }

    static async request(
        path: string,
        data: GwServiceRequestData,
        headers: GwServiceRequestHeaders,
        ignoreErrors: boolean = false
    ) {
        return request(
            `${this.addr()}${path}`,
            data as any,
            'POST',
            headers
                ? headers
                : {
                      'Content-Type': 'application/json',
                      ...this.headers(),
                  },
            ignoreErrors
        );
    }

    static async validate() {
        // @ts-ignore
        return this.request(`/validate`);
    }

    static async liveness(
        data: GwLivenessRequestData
    ): Promise<GwLivenessResonseData> {
        // @ts-ignore
        return this.request(`/liveness`, data);
    }

    static async ping() {
        // @ts-ignore
        return this.request(`/ping`);
    }
    static async log(message: string) {
        // @ts-ignore
        return this.request(`/log`, { message });
    }
    static async logError(message: string) {
        // @ts-ignore
        return this.request(`/logError`, { message });
    }
    static async update(data: {
        index: number;
        result: { [key: string]: any };
    }) {
        // @ts-ignore
        return this.request(`/update`, { data });
    }
    static async getClientConfiguration(clientId: string) {
        // @ts-ignore
        return this.request(
            `/clientConfig/${clientId || this.headers()['client-id']}`
        );
    }

    static async sendResult({
        disableSendResult,
        error,
    }: SendResultRequestData) {
        // @ts-ignore
        return this.request(`/sendResult`, { disableSendResult });
    }
    static async login({
        clientId,
        pass,
    }: LoginRequestData): Promise<LoginResponseData> {
        return this.request(
            `/login`,
            { clientId, pass },
            {
                'Content-Type': 'application/json',
            }
        );
    }
    static async logout() {
        return this.request(`/logout`, {}, {});
    }
    static async track({ geoLocation }: TrackRequestData) {
        // @ts-ignore
        return this.request(`/track`, { geoLocation });
    }

    static async sendOtpEmail({ recipient }: SendOtpEmailRequestData) {
        // @ts-ignore
        return this.request(`/sendOtpEmail`, { recipient });
    }
    static async validateOtpEmail({
        recipient,
        otp,
    }: ValidateOtpEmailRequestData) {
        // @ts-ignore
        return this.request(`/validateOtpEmail`, { recipient, otp });
    }

    static async sendOtpSms({ recipient }: SendOtpEmailRequestData) {
        // @ts-ignore
        return this.request(`/sendOtpSms`, { recipient });
    }
    static async validateOtpSms({
        recipient,
        otp,
    }: ValidateOtpEmailRequestData) {
        // @ts-ignore
        return this.request(`/validateOtpSms`, { recipient, otp });
    }
}
