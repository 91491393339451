import React, { useMemo, useState } from 'react';
import { GwFlowStepProps, GwFlowStepResult } from '../GwFlow';
import { GwConfigStepType, GwFlowValidateOptions } from 'gw-api/types';
import TitleText from '../../common/TitleText';
import { Tabs } from 'antd';
import StepContent from '../../common/StepContent';
import { useTranslation } from 'react-i18next';

import GwFlowValidateDo from './GwFlowVadiateDo';
import GwFlowValidateSuccess from './GwFlowVadiateSuccess';
import GwFlowValidateError from './GwFlowVadiateError';
import { useConfigContext } from '../ConfigContext';
export default GwFlowValidate;

export type GwFlowValidateResult = GwFlowStepResult & {
    status: 'success' | 'error' | 'pending';
    successResult?: any;
    errorMessage?: string;
    backToStep?: GwConfigStepType;
};

export interface GwFlowValidateProps extends Omit<GwFlowStepProps, 'options'> {
    options?: GwFlowValidateOptions;
}

function GwFlowValidate({
    onResult,
    onCancel,
    options,
    currentResult,
}: Partial<GwFlowValidateProps>) {
    const { t } = useTranslation();
    const [tmpResult, setTmpResult] = useState<GwFlowValidateResult>({
        status: 'pending',
    });
    const { transactionId } = useConfigContext();

    const {
        showSummary,
        autoContinueSeconds,
        successTitle,
        errorTitle,
        successSubtitle,
        errorSubtitle,
        successButtonText,
        errorButtonText,
        supportLink,
    } = options || {};

    const handleValidateSuccess = (result: any) => {
        setTmpResult({ status: 'success', successResult: result });
        setActiveKey('success');
    };
    const handleValidateError = (error: any) => {
        setTmpResult({ status: 'error', errorMessage: error.message });
        setActiveKey('error');
    };
    const handleFinishSuccessClick = () => {
        onResult?.(tmpResult);
    };
    const handleFinishTimeout = () => {
        onResult?.(tmpResult);
    };
    const handleFinishErrorClick = () => {
        onResult?.(tmpResult);
    };

    const items = useMemo(
        () => [
            {
                label: '',
                key: 'validate',
                children: (
                    <GwFlowValidateDo
                        onSuccess={handleValidateSuccess}
                        onError={handleValidateError}
                    />
                ),
            },
            {
                label: '',
                key: 'success',
                children: (
                    <StepContent
                        body={
                            <GwFlowValidateSuccess
                                showSummary={showSummary}
                                result={currentResult}
                                onFinishClick={handleFinishSuccessClick}
                                finishTimeoutMs={autoContinueSeconds}
                                onFinishTimeout={handleFinishTimeout}
                                title={successTitle}
                                subtitle={successSubtitle}
                                buttonText={successButtonText}
                            />
                        }
                    />
                ),
            },
            {
                label: '',
                key: 'error',
                children: (
                    <StepContent
                        body={
                            <GwFlowValidateError
                                onFinishClick={handleFinishErrorClick}
                                title={errorTitle}
                                subtitle={errorSubtitle}
                                buttonText={errorButtonText}
                                supportLink={supportLink}
                                transactionId={transactionId}
                            />
                        }
                    />
                ),
            },
        ],
        [options, tmpResult]
    );
    const [activeKey, setActiveKey] = useState<string>(items[0].key);

    return (
        <Tabs
            items={items}
            renderTabBar={() => <span></span>}
            activeKey={activeKey}
            destroyInactiveTabPane={true}
        />
    );
}
