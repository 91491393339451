import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import GwService from '../services/GwService';

// const LOG_ENTRY_LEVEL_DEBUG = 'debug';
const LOG_ENTRY_LEVEL_INFO = 'info';
// const LOG_ENTRY_LEVEL_WARN = 'warn';
const LOG_ENTRY_LEVEL_ERROR = 'error';

export enum LogEntryLevel {
    INFO = 'info',
    ERROR = 'error'
}

export interface LogEntry {
    level: LogEntryLevel;
    message: string;
    fullMessage: string;
    timestamp: number;
}

export type DebugContextProps = {
    children?: React.ReactNode;
    initialDebug?: boolean;
    initialEnableError?: boolean;

}

export type DebugContextApi = {
    debug:boolean;
    setDebug: Dispatch<SetStateAction<boolean>>;
    enableError: boolean;
    setEnableError: Dispatch<SetStateAction<boolean>>,
    log: (...args: any[]) => void,
    logError: (input: string | Error, ...args: any[]) => void,
    getLogs: (print?: boolean) => void,
}

export const DebugContext = React.createContext<DebugContextApi>({} as DebugContextApi);

export const DebugProvider = ({
    children,
    initialDebug = false,
    initialEnableError = false,
}:DebugContextProps) => {
    const [debug, setDebug] = useState(initialDebug);
    const [enableError, setEnableError] = useState(initialEnableError);
    const logEntries = useRef<LogEntry[]>([]);

    const logEntry = useCallback(
        (level: LogEntryLevel, ...args: any[]) => {
            const now = new Date();
            const pr = `[${now.toISOString()}] [${
                level || LOG_ENTRY_LEVEL_INFO
            }]`;
            const message = Array.from(args).join(' ');
            const fullMessage = `${pr} ${message}`;
            logEntries.current.push({
                level,
                message,
                fullMessage,
                timestamp: Math.floor(now.getTime() / 1000),
            });
            if (debug || level === LOG_ENTRY_LEVEL_ERROR) {
                console.log(pr, ...args);
            }
        },
        [debug]
    );
    const log = useCallback(
        async (...args: any[]) => {
            logEntry(LogEntryLevel.INFO, ...args);
            try {
                await GwService.log(args.join(', '));
            } catch (err) {}
        },
        [logEntry]
    );
    const logError = useCallback(
        async (input: string | Error, ...args: any[]) => {
            logEntry(
                LogEntryLevel.ERROR,
                input instanceof Error ? input.message :input,
                ...args
            );

            try {
                await GwService.logError(
                    [(input as Error).message || input, ...args].join(', ')
                );
            } catch (err) {}

            if (enableError && (input as Error).message) {
                throw input;
            }
        },
        [logEntry, enableError]
    );
    const getLogs = useCallback((print?: boolean) => {
        if (print) {
            return logEntries.current
                .map(
                    ({timestamp, level, message}) =>
                        `[${new Date(timestamp * 1000).toISOString()}] ${level.toUpperCase()}: ${message}`
                )
                .join('\n');
        } else {
            return logEntries.current;
        }
    }, []);

    const debugContext: DebugContextApi = {
        debug,
        setDebug,
        enableError,
        setEnableError,
        log,
        logError,
        getLogs,
    };
    return (
        <DebugContext.Provider value={debugContext}>
            {children}
        </DebugContext.Provider>
    );
};
