import React, { useState, useEffect } from 'react';
import './SquareImage.css';
import classNames from 'classnames';

export interface SquareImageProps {}
function SquareImage({ className, src, alt, ...rest }: SquareImageProps) {
    const [style, setStyle] = useState({});
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setLoaded(true);
            if (img.naturalWidth > img.naturalHeight) {
                // Crop by width
                setStyle({
                    width: '100%',
                    height: img.naturalHeight,
                    maxWidth: '200px',
                    maxHeight: '200px',
                    objectFit: 'cover',
                });
            } else {
                // Crop by height
                setStyle({
                    width: img.naturalWidth,
                    height: '100%',
                    maxWidth: '200px',
                    maxHeight: '200px',
                    objectFit: 'cover',
                });
            }
        };
        img.src = src;
    }, [src]);

    return (
        <img
            className={classNames('SquareImage', className, {
                'SquareImage--loaded': loaded,
            })}
            src={src}
            style={{
                width: '100%',
                maxWidth: '300px',
                height: '100%',
                maxHeight: '300px',
                objectFit: 'cover',
                ...style,
            }}
            alt=""
            {...rest}
        />
    );
}

export default SquareImage;
