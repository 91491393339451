import { Space } from 'antd';
import Button from './Button';
import React, { Fragment } from 'react';
import StepContent from './StepContent';

export default Confirm;

export type ConfirmProps = {
    title?: React.ReactNode;
    content?: React.ReactNode;
    confirmText?: string;
    cancelText?: string;

    onConfirm?: () => void;
    onCancel?: () => void;
};

function Confirm({
    title,
    content,
    confirmText = 'Aceptar',
    cancelText = 'Cancelar',
    onConfirm,
    onCancel,
}: ConfirmProps) {
    const handleOnConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
    };

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    return (
        <StepContent
            header={title ? <div key="title">{title}</div> : null}
            body={
                <Space direction="vertical" size={20}>
                    <Fragment>{content}</Fragment>
                    <Button type="primary" onClick={handleOnConfirm}>
                        {confirmText}
                    </Button>
                    <Button onClick={handleOnCancel}>{cancelText}</Button>
                </Space>
            }
        />
    );
}
