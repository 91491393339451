import React, {forwardRef, ForwardRefRenderFunction} from 'react';
import {Input as AntInput, InputProps as AntInputProps} from 'antd'
import './Input.css';
import classNames from "classnames";


export type InputProps = AntInputProps &{

};

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ className, ...rest}, ref:any) => {
    return (
      <AntInput className={classNames('Input', className)} {...rest} ref={ref} />
    );
};

const ForwardRefInput = forwardRef(Input)

ForwardRefInput.Group = AntInput.Group;

export default ForwardRefInput;

