import React from 'react';
import './GwFlowWelcome.css';
import { GwFlowStepProps, GwFlowStepResult } from '../GwFlow';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import TitleText from '../../common/TitleText';
import Button from '../../common/Button';
import { RightOutlined } from '@ant-design/icons';
import StepContent from '../../common/StepContent';
import TermsAndConditionsInput from './TermsAndConditionsInput';
import Space from '../../common/Space';

import { GwFlowWelcomeOptions } from 'gw-api/types';
export default GwFlowWelcome;

export interface GwFlowWelcomeFormValues {
    accepted: boolean;
}

export type GwFlowWelcomeResult = GwFlowStepResult & {
    accepted: boolean;
};

export interface GwFlowWelcomeProps extends Omit<GwFlowStepProps, 'options'> {
    options?: GwFlowWelcomeOptions;
}

function GwFlowWelcome({
    options,
    onResult,
    onCancel,
}: Partial<GwFlowWelcomeProps>) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { logoSrc } = options || {};

    const handleFormFinish = (values: GwFlowWelcomeFormValues) => {
        if (values.accepted) {
            onResult?.(values as GwFlowWelcomeResult);
        } else {
            form.validateFields();
        }
    };
    const handleBackClick = () => {
        onCancel?.();
    };

    return (
        <StepContent
            header={
                <Space direction="vertical">
                    <TitleText size="large">
                        {t('Vamos a verificar tu identidad')}
                    </TitleText>
                    {logoSrc ? (
                        <img className="GwFlowWelcome_logo" src={logoSrc} />
                    ) : null}
                </Space>
            }
            body={
                <div>
                    <Form<GwFlowWelcomeFormValues>
                        form={form}
                        onFinish={handleFormFinish}
                        layout={'vertical'}
                    >
                        <Form.Item
                            name="accepted"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value
                                            ? Promise.resolve()
                                            : Promise.reject(
                                                  new Error(
                                                      t(
                                                          'Por favor acepta los términos y condiciones'
                                                      )
                                                  )
                                              ),
                                },
                            ]}
                        >
                            <TermsAndConditionsInput
                                terms={
                                    <div>
                                        <h1>
                                            Términos y Condiciones de la
                                            Pasarela de Identificación Facial
                                            Web y Móvil
                                        </h1>

                                        <p>
                                            Bienvenido/a a nuestra pasarela de
                                            identificación facial web y móvil
                                            ("la Pasarela"). La Pasarela es
                                            proporcionada por [nombre de la
                                            empresa], y está diseñada para
                                            ayudar a nuestros usuarios a
                                            verificar su identidad de forma
                                            segura y eficiente utilizando
                                            tecnología de reconocimiento facial.
                                            Al utilizar la Pasarela, usted
                                            acepta los siguientes términos y
                                            condiciones:
                                        </p>

                                        <h2>
                                            Aceptación de los Términos y
                                            Condiciones
                                        </h2>
                                        <p>
                                            Al utilizar la Pasarela, usted
                                            acepta cumplir con estos términos y
                                            condiciones, así como con todas las
                                            leyes y regulaciones aplicables.
                                        </p>

                                        <h2>Uso de la Pasarela</h2>
                                        <p>
                                            La Pasarela solo puede ser utilizada
                                            para verificar su propia identidad
                                            personal. Usted no puede utilizar la
                                            Pasarela para verificar la identidad
                                            de otra persona sin su
                                            consentimiento explícito y previo.
                                        </p>

                                        <h2>Recopilación y uso de datos</h2>
                                        <p>
                                            La Pasarela recopila datos de
                                            reconocimiento facial para verificar
                                            su identidad. Estos datos se
                                            utilizarán únicamente para el
                                            propósito de la verificación de
                                            identidad y no se compartirán con
                                            terceros sin su consentimiento
                                            explícito y previo, a menos que se
                                            requiera por ley o por orden
                                            judicial.
                                        </p>

                                        <h2>Seguridad</h2>
                                        <p>
                                            Nos tomamos muy en serio la
                                            seguridad de sus datos personales y
                                            utilizamos medidas de seguridad
                                            razonables para protegerlos contra
                                            el acceso no autorizado, la
                                            divulgación, la alteración o la
                                            destrucción. Sin embargo, no podemos
                                            garantizar la seguridad absoluta de
                                            sus datos personales.
                                        </p>

                                        <h2>Responsabilidad</h2>
                                        <p>
                                            Usted es responsable de cualquier
                                            uso que haga de la Pasarela y de
                                            cualquier daño o perjuicio que cause
                                            a terceros como resultado de dicho
                                            uso. Usted indemnizará y mantendrá
                                            indemne a [nombre de la empresa] y
                                            sus afiliados, funcionarios,
                                            directores, empleados y agentes de
                                            cualquier reclamación, demanda o
                                            daño, incluidos los honorarios de
                                            abogados razonables, que surjan de
                                            su uso de la Pasarela.
                                        </p>

                                        <h2>
                                            Modificaciones a los Términos y
                                            Condiciones
                                        </h2>
                                        <p>
                                            Nos reservamos el derecho de
                                            modificar estos términos y
                                            condiciones en cualquier momento y
                                            sin previo aviso. La versión más
                                            reciente de los términos y
                                            condiciones siempre estará
                                            disponible en nuestro sitio web. Si
                                            no está de acuerdo con los términos
                                            y condiciones modificados, debe
                                            dejar de utilizar la Pasarela de
                                            inmediato.
                                        </p>

                                        <h2>Resolución de conflictos</h2>
                                        <p>
                                            Cualquier disputa relacionada con
                                            estos términos y condiciones se
                                            resolverá mediante arbitraje
                                            vinculante de conformidad con las
                                            reglas de arbitraje comercial de la
                                            Asociación Americana de Arbitraje.
                                            El arbitraje se llevará a cabo en
                                            [ciudad y estado], y el fallo del
                                            árbitro será definitivo y vinculante
                                            para ambas partes.
                                        </p>

                                        <h2>Ley aplicable</h2>
                                        <p>
                                            Estos términos y condiciones se
                                            regirán e interpretarán de acuerdo
                                            con las leyes del estado de [nombre
                                            del estado], Estados Unidos, sin
                                            tener en cuenta sus conflictos de
                                            leyes.
                                        </p>

                                        <h2>Contacto</h2>
                                        <p>
                                            Si tiene alguna pregunta o inquietud
                                            acerca de estos términos y
                                            condiciones, no dude en ponerse en
                                            contacto con nosotros. Gracias por
                                            utilizar nuestra Pasarela de
                                            Identificación Facial Web y Móvil.
                                        </p>
                                    </div>
                                }
                            />
                        </Form.Item>
                        <Button htmlType="submit" type="primary">
                            {t('Comenzar')}
                        </Button>
                    </Form>
                </div>
            }
        />
    );
}
