import React, { useState, ReactNode } from 'react';
import { Checkbox, CheckboxProps, Collapse } from 'antd';
import TitleText from '../../common/TitleText';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Space from '../../common/Space';

export interface TermsAndConditionsInputProps extends CheckboxProps {
    terms: ReactNode;
    termsHeader?: ReactNode;
    onChange?: (e: CheckboxChangeEvent) => void;
    value?: boolean;
}

const TermsAndConditionsInput = ({
    terms,
    termsHeader,
    onChange,
    value,
    ...props
}: TermsAndConditionsInputProps) => {
    const { t } = useTranslation();
    const [showTerms, setShowTerms] = useState(false);
    const [agreed, setAgreed] = useState(value || false);

    const toggleTerms = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        setShowTerms(!showTerms);
    };

    const onAgree = (e: any) => {
        const newValue = e.target.checked;
        setAgreed(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <Space wide={true} direction="vertical">
            {showTerms && (
                <div
                    className="TermsAndConditionsInput_terms-container"
                    style={{
                        maxHeight: '400px',
                        overflowY: 'scroll',
                        border: '1px solid #cccccc',
                        padding: '10px',
                        borderRadius: '10px',
                        textAlign: 'left',
                    }}
                >
                    {terms}
                </div>
            )}

            <div style={{ marginTop: '10px', textAlign: 'left' }}>
                <Checkbox {...props} checked={agreed} onChange={onAgree}>
                    {t('Estoy de acuerdo con los')}{' '}
                    <a
                        onClick={toggleTerms}
                        href="#"
                        style={{ textDecoration: 'underline' }}
                    >
                        {t('terminos y condiciones')}
                    </a>
                </Checkbox>
            </div>
        </Space>
    );
};

export default TermsAndConditionsInput;
