import React, { CSSProperties } from 'react';
import './TitleText.css';
import classNames from 'classnames';
export default TitleText;

export type TitleTextProps = {
    style?: CSSProperties;
    size?: string;
    children?: React.ReactNode;
}
function TitleText({ children, size, ...rest }: TitleTextProps) {
    return (
        <span
            className={classNames('TitleText', `TitleText--size-${size}`)}
            {...rest}
        >
            {children}
        </span>
    );
}
