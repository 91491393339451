import React from 'react';
import TitleText from './common/TitleText';
import ContentBox from './common/ContentBox';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import {
    MobileOutlined,
    ArrowRightOutlined,
    LaptopOutlined,
} from '@ant-design/icons';
import StepContent from './common/StepContent';
import { useConfigContext } from './components/ConfigContext';

export type SessionTransferedBackMessageProps = {
    clientConfiguration?: any;
    extra?: any;
};

export default function SessionTransferedBackMessage({
    clientConfiguration,
    extra,
}: SessionTransferedBackMessageProps) {
    const { t } = useTranslation();
    const { config } = useConfigContext();
    return (
        <StepContent
            header={
                <TitleText size="large">
                    {t('El proceso continuará en el dispositivo anterior')}
                </TitleText>
            }
            body={
                <Space direction="vertical">
                    <Space
                        style={{
                            fontSize: '96px',
                            color: config?.uiConfig?.primaryColor,
                        }}
                    >
                        <MobileOutlined />
                        <div style={{ fontSize: '48px' }}>
                            <ArrowRightOutlined />
                        </div>
                        <LaptopOutlined />
                    </Space>
                    {t('Puedes cerrar esta ventana del navegador')}
                    {extra}
                </Space>
            }
        />
    );
}
