import React from 'react';
import { Collapse, Modal } from 'antd';
import { debugDataWithModal } from './DebugComponentsPage';
import {  GwFlowStepResult } from '../components/GwFlow';
import GwFlowUploads, {
    GwFlowUploadsProps,
} from '../components/GwFlowUploads/GwFlowUploads';

export default DebugGwFlowUploads;
export type DebugGwFlowUploadsProps = {};

function DebugGwFlowUploads({ ...rest }: DebugGwFlowUploadsProps) {
    const onResult = (result: GwFlowStepResult) => {
        const { rawScanResult, ...rest } = result;
        debugDataWithModal('', {
            ...rest,
        });
    };
    const samples: {
        title: string;
        props: GwFlowUploadsProps;
    }[] = [
        {
            title: 'Default',
            props: {
                options: {},
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
            },
        },
        {
            title: 'with custom items with different accepts and sizes',
            props: {
                options: {
                    items: [
                        {
                            label: 'hello',
                            key: 'document',
                            accept: '.jpg',
                            maxMb: 0.2,
                        },
                        {
                            label: 'hello',
                            key: 'document1',
                            accept: '.png',
                            maxMb: 5,
                        },
                    ],
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
            },
        },
        {
            title: 'with custom options accept (pdf), max 1MB',
            props: {
                options: {
                    accept: '.pdf',
                    maxMb: 1,
                    items: [{ label: 'hello', key: 'document' }],
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
            },
        },
    ];
    return (
        <Collapse destroyInactivePanel={true}>
            {samples.map(({ title, props }, index) => {
                return (
                    <Collapse.Panel header={title} key={`fragment-${index}`}>
                        <GwFlowUploads {...props} />
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    );
}
