import React from 'react';
import { Space } from 'antd';
import Icon, { CameraOutlined } from '@ant-design/icons';
import { imgDocumentFrontSvg } from '../../common/images';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../common/Button';

export default GwFlowDocumentCaptureTips;

export type GwFlowDocumentCaptureTipsProps = {
    onClick: () => void;
};

function GwFlowDocumentCaptureTips({
    onClick,
}: GwFlowDocumentCaptureTipsProps) {
    const { t } = useTranslation();
    return (
        <div className="GwFlowDocumentCaptureTips">
            <Space direction="vertical" size={20}>
                <Space>
                    <Icon component={imgDocumentFrontSvg} />
                </Space>

                <Trans
                    i18nKey="Busca un fondo con <1>buen contraste</1> y toma una foto <1>clara y nítida</1> de tu documento."
                    values={
                        {
                            // document: t(documentName as string),
                        }
                    }
                >
                    Busca un fondo con
                    <strong>buen contraste</strong> y toma una foto
                    <strong>clara y nítida</strong> de tu documento.
                </Trans>
                <Trans i18nKey="Asegúrate de tener <1>buena conexión a internet</1>.">
                    Asegúrate de tener
                    <strong>buena conexión a internet</strong>.
                </Trans>
                <Button type="primary" onClick={onClick}>
                    <CameraOutlined /> {t('Tomar fotografía')}
                </Button>
            </Space>
        </div>
    );
}
