import countries from './data/countries.json';
// @ts-ignore
import Color from 'color';
import { ClientParams } from './App';
import { ClientData } from 'gw-api/types';
import { ApiError } from 'gw-api/error';
import { CountryInfo } from './components/GwFlowDocumentTypeSelect/CountrySelect';

export interface ClientParamsRaw {
    clientId?: string | number | null;
    clientData?: string;
}
export interface ClientDataRaw {
    maxRetries?: string | number;
    authenticateTime?: string | number;
    documentCaptureRetries?: string | number;
    selfieCaptureRetries?: string | number;
    countryCode?: string;
    saasMode?: string | number;
    fingerprintCode?: string;
    civilCheck?: string | boolean | number;
}

const countryMap: { [key: string]: CountryInfo } = countries;

//@ts-ignore
const SORTVARS: ClientParamsRaw = window.SORTVARS;

export function getClientId(): number | null {
    let clientId = SORTVARS?.clientId || null;
    if (!clientId) {
        clientId = 1;
    }
    return clientId as number;
}

export function getClientData(): ClientData {
    let newClientData: ClientData = {
        countryCode: '',
        saasMode: 1,
        fingerprintCode: '',
        civilCheck: false,
    };
    let parsedClientData: ClientDataRaw = {};
    let validClientData = true;
    if (SORTVARS?.clientData) {
        try {
            parsedClientData = JSON.parse(
                SORTVARS?.clientData
            ) as ClientDataRaw;
        } catch (e) {
            validClientData = false;
            console.error('Could not parse clientData', SORTVARS.clientData);
        }
    }

    const {
        maxRetries: parsedMaxRetries,
        civilCheck: parsedCivilCheck,
        saasMode: parsedSaasMode,
        authenticateTime: parsedAuthenticateTime,
        documentCaptureRetries: parsedDocumentCaptureRetries,
        selfieCaptureRetries: parsedSelfieCaptureRetries,
        ...restParsedClientData
    } = parsedClientData;

    if (parsedMaxRetries) {
        let numRetries = Number(parsedMaxRetries);
        if (!Number.isNaN(numRetries) && numRetries > 0 && numRetries <= 3) {
            // usamos maxRetries de la configuracion si retries es un numero y es mayor que 0 y menor que 4
            newClientData.maxRetries = numRetries;
        }
    }

    if (parsedCivilCheck) {
        newClientData.civilCheck =
            parsedCivilCheck === true || parsedCivilCheck === 'true';
    }

    if (parsedSaasMode) {
        let numSaasMode = Number(parsedSaasMode);
        if (!Number.isNaN(numSaasMode)) {
            // usamos numSaasMode si es numero
            newClientData.saasMode = numSaasMode;
        }
    }

    if (parsedAuthenticateTime) {
        let numAuthenticateTime = Number(parsedAuthenticateTime);
        if (!Number.isNaN(numAuthenticateTime)) {
            // usamos numAuthenticationTime si es numero
            newClientData.authenticateTime =
                numAuthenticateTime > 10 ? 10 : numAuthenticateTime;
        }
    }

    if (parsedDocumentCaptureRetries) {
        let numDocumentCaptureRetries = Number(parsedDocumentCaptureRetries);
        if (!Number.isNaN(numDocumentCaptureRetries)) {
            // usamos numDocumentCaptureRetries si es numero
            newClientData.documentCaptureRetries =
                numDocumentCaptureRetries > 5 ? 5 : numDocumentCaptureRetries;
        }
    }

    if (parsedSelfieCaptureRetries) {
        let numSelfieCaptureRetries = Number(parsedSelfieCaptureRetries);
        if (!Number.isNaN(numSelfieCaptureRetries)) {
            // usamos numSelfieCaptureRetries si es numero
            newClientData.selfieCaptureRetries =
                numSelfieCaptureRetries > 5 ? 5 : numSelfieCaptureRetries;
        }
    }

    return validClientData
        ? { ...newClientData, ...restParsedClientData }
        : newClientData;
}

export function isCountryCodeValid(code?: string) {
    return !code || (code && code === 'PSP') || countryMap[code];
}

export function getClientParams(sortVars: any) {
    let result: ClientParams = sortVars as ClientParams;
    if (result.redirectMethod) {
        result.redirectMethod = ['post', 'get', 'POST', 'GET'].includes(
            result.redirectMethod
        )
            ? result.redirectMethod
            : 'post';
    }
    if (result.returnPostData) {
        result.returnPostData = !!['true', '1', true].includes(
            result.returnPostData
        );
    } else {
        result.returnPostData = true;
    }
    result.error = result.error
        ? ApiError.fromResponse(result.error as any)
        : null;

    return result || {};
}

export function createStylesheet(uiConfig: any) {
    const rules = [];

    if (uiConfig.primaryColor) {
        rules.push(`
            .ant-btn.ant-btn-primary:not(.ant-btn[disabled]), 
            .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after,
            .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon { 
            background-color: ${uiConfig.primaryColor}; border-color: ${Color(
                uiConfig.primaryColor
            ).lighten(0.9)};}
            a, 
            .ant-radio-button-wrapper.ant-radio-button-wrapper-checked ,
            .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover,
            .ant-btn.ant-btn-link:not(.ant-btn[disabled]), 
            .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon,
             .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon, 
             .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon, .Summary_item-icon {
            color: ${uiConfig.primaryColor};
            }
            .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
            .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
                border-color: ${uiConfig.primaryColor};
                box-shadow: 0 0 0 2px ${Color(uiConfig.primaryColor)
                    .alpha(0.3)
                    .lighten(0.1)};
            }
            .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
                background-color: ${Color(uiConfig.primaryColor)
                    .alpha(0.3)
                    .lighten(0.1)};
            }
            .ValidatoinWizardDocument_radio-item:hover:not(.ant-radio-button-wrapper-disabled), .Image, .ant-steps-item-finish .ant-steps-item-icon,
             .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover, 
            .DocumentTypeBoxSelect_radio-item.DocumentTypeBoxSelect_radio-item--selected,
             .ant-checkbox-checked .ant-checkbox-inner {
                border-color: ${uiConfig.primaryColor} !important;
            }
            .img-document-front-scanner, .img-document-back-scanner, .img-selfie-scanner{
                stroke: ${uiConfig.primaryColor} !important;
            }
            .img-result-success{
                fill: ${uiConfig.primaryColor} !important;
            }
            .ant-spin-dot-item,.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after, 
            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: ${uiConfig.primaryColor}
            }
        `);
    }
    if (uiConfig.borderRadiusBase) {
        rules.push(`
            .ant-btn:not(.ant-btn-circle), 
            .ant-input,
            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector { 
                border-radius: ${uiConfig.borderRadiusBase}; 
            }
        `);
        rules.push(`
        .ant-input-group.ant-input-group-compact > *:last-child, .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-top-right-radius: ${uiConfig.borderRadiusBase};
    border-bottom-right-radius: ${uiConfig.borderRadiusBase};
}`);
        rules.push(`.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector {
            border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
        }`);
    }
    let style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = rules.join('');
    window.document.getElementsByTagName('head')[0].appendChild(style);
}

export function updateFavicon(url: string) {
    document.querySelector('[rel=icon]')?.setAttribute('href', url);
}

export function getFormElement(data: any, action: string, method: string) {
    const form = window.document.createElement('form');
    form.setAttribute('action', action);
    form.setAttribute('method', method);

    for (let name in data) {
        const input = window.document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', name);
        input.setAttribute(
            'value',
            typeof data[name] === 'object' && data[name] !== null
                ? JSON.stringify(data[name])
                : data[name]
        );
        form.appendChild(input);
    }
    return form;
}

export function submitForm(data: any, action: string, method: string) {
    const form = getFormElement(data, action, method);
    window.document.body.appendChild(form);
    form.submit();
}

export function cleanUpDocumentData(data: any) {
    const {
        faceImage,
        fullDocumentFrontImage,
        fullDocumentBackImage,
        fullDocumentImage,
        signatureImage,
        ...rest
    } = data;
    // @ts-ignore
    return rest;
}

export function getShowSummary(clientData: ClientData) {
    return !!clientData.showSummary && clientData.saasMode === 1;
}

export function getQrDeviceTransfer(clientData: ClientData) {
    return !!clientData.qrDeviceTransfer || clientData.saasMode === 4;
}
