import React from 'react';
import './BackButtonWrapper.css';
import ButtonBack from './ButtonBack';
import LoadingWrapper, { LoadingWrapperProps } from './LoadingWrapper';

export default BackButtonWrapper;

export type BackButtonWrapperProps = LoadingWrapperProps & {
    children: React.ReactNode;
    headerExtra?: React.ReactNode;
    onBackClick?: () => void;
    showBack?: boolean;
};

function BackButtonWrapper({
    children,
    headerExtra,
    onBackClick,
    loading,
    showBack = true,
}: BackButtonWrapperProps) {
    return (
        <LoadingWrapper className="BackButtonWrapper" loading={loading}>
            {children}
            {!loading ? (
                <div className="BackButtonWrapper-header">
                    <div>
                        {showBack ? <ButtonBack onClick={onBackClick} /> : null}
                    </div>
                    <div className="BackButtonWrapper-header-right">
                        {headerExtra}
                    </div>
                </div>
            ) : null}
        </LoadingWrapper>
    );
}
