import React, { useState, ChangeEvent, ClipboardEvent, useRef } from 'react';
import Input  from './Input';
import "./OtpInput.css";
import Space from "./Space";

interface OtpInputProps {
    length?: number;
    onChange?: (value: string) => void;
    disabled?: boolean;
}

const OtpInput: React.FC<OtpInputProps> = ({
    length = 6,
    onChange,
    disabled = false,
}) => {
    const [value, setValue] = useState<string[]>(
        Array.from({ length }, () => '')
    );
    const inputRefs = useRef<Input[]>(Array.from({ length }, () => null));

    const focusNextInput = (currentIndex: number) => {
        const nextIndex = currentIndex + 1;
        if (nextIndex < length && inputRefs.current[nextIndex]) {
            inputRefs.current[nextIndex].focus();
        }
    };

    const handleChange = (
        event: ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const newValue = event.target.value.replace(/[^\d]/g, '').substr(0, 1);
        const newValueArray = [...value];
        newValueArray[index] = newValue;
        setValue(newValueArray);
        onChange?.(newValueArray.join(''));
        if (newValue.length === 1) {
            focusNextInput(index);
        }
    };

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        const pasteData = event.clipboardData.getData('text/plain');
        const newValueArray = Array.from(
            pasteData.replace(/[^\d]/g, '').substr(0, length)
        );
        const newValues = Array.from(
            { length },
            (_, i) => newValueArray[i] || ''
        );
        setValue(newValues);
        onChange?.(newValues.join(''));
        focusNextInput(0);
    };

    return (
        <Space className="OtpInput">
            {Array.from({ length }).map((_, index) => (
                <Input
                    key={index}
                    value={value[index]}
                    onChange={(event) => handleChange(event, index)}
                    onPaste={handlePaste}
                    maxLength={1}
                    type="tel"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    ref={(input) => (inputRefs.current[index] = input)}
                    disabled={disabled}
                    className="OtpInput_input"
                />
            ))}
        </Space>
    );
};

export default OtpInput;
