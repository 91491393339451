import React, { HTMLProps } from 'react';
import './StepContent.css';

export default StepContent;

export type StepContentProps = {
    header?: React.ReactNode;
    body?: React.ReactNode;
    footer?: React.ReactNode;
    headerProps?: HTMLProps<HTMLDivElement>;
    bodyProps?: HTMLProps<HTMLDivElement>;
    footerProps?: HTMLProps<HTMLDivElement>;
};

function StepContent({
    header,
    body,
    footer,
    headerProps,
    bodyProps,
    footerProps,
}: StepContentProps) {
    return (
        <div className="StepContent">
            {header ? (
                <div className="StepContent_header" {...headerProps}>
                    {header}
                </div>
            ) : null}
            <div className="StepContent_body" {...bodyProps}>
                {body}
            </div>
            {footer ? (
                <div className="StepContent_footer" {...footerProps}>
                    {footer}
                </div>
            ) : null}
        </div>
    );
}
