import React, { CSSProperties } from 'react';
import './Space.css';
import { Space as AntSpace, SpaceProps as AntSpaceProps } from 'antd';
import classNames from 'classnames';

export default Space;

export type SpaceProps = AntSpaceProps & {
    wide?: boolean;
    justify?: CSSProperties['justifyContent'];
};

function Space({
    wide,
    style,
    className,
    justify = 'left',
    ...rest
}: SpaceProps) {
    return (
        <AntSpace
            className={classNames('Space', { 'Space--wide': wide }, className)}
            style={{ justifyContent: justify, ...style }}
            {...rest}
        />
    );
}
