import React, { Fragment, useState } from 'react';
import { QrcodeOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import QrCode from '../QrCode';
import { useTranslation } from 'react-i18next';

export default UseMyMobile;

export type UseMyMobileProps = {
    defaultMobileConnected?: boolean;
    onIndexChanged?: (index: number) => void;
    onResultSent?: (result: any) => void;
    closable?: boolean;
    onOpenChange?: (open: boolean) => void;
    defaultVisible?: boolean;
};

function UseMyMobile({
    defaultVisible = false,
    closable = true,
    onOpenChange,
}: UseMyMobileProps) {
    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(defaultVisible);
    return (
        <Fragment>
            {/*<Tooltip title="Use my mobile">*/}
            <Button
                shape="circle"
                type="link"
                size="small"
                icon={<QrcodeOutlined />}
                onClick={() => {
                    setVisible(true);
                    onOpenChange?.(true);
                }}
            >
                {t('Usar mi móvil')}
            </Button>
            {/*</Tooltip>*/}
            <Modal
                closable={closable}
                footer={null}
                open={visible}
                bodyStyle={{ padding: 0 }}
                onCancel={() => {
                    setVisible(false);
                    onOpenChange?.(false);
                }}
                maskClosable={false}
            >
                <QrCode showLink={true} />
            </Modal>
        </Fragment>
    );
}
