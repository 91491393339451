import React from 'react';
import {
    DatePicker as AntDatePicker,
    DatePickerProps as AntDatePickerProps,
} from 'antd';
import './DatePicker.css';

export default DatePicker;

export type DatePickerProps = AntDatePickerProps & {};

function DatePicker({ ...rest }: DatePickerProps) {
    return <AntDatePicker className="DatePicker" {...rest} />;
}
