import React, { useEffect, useState } from 'react';
import { Collapse, Modal } from 'antd';
import { debugDataWithModal } from './DebugComponentsPage';
import { GwFlowStepResult } from '../components/GwFlow';
import GwFlowValidate, {
    GwFlowValidateProps,
} from '../components/GwFlowValidate/GwFlowValidate';
import { ConfigProvider } from '../components/ConfigContext';
import currentResultForValidate from '../mock/currentResultForValidate';
import GwService from '../services/GwService';

export default DebugGwFlowValidate;
export type DebugGwFlowValidateProps = {};

function DebugGwFlowValidate({ ...rest }: DebugGwFlowValidateProps) {
    const [ready, setReady] = useState<boolean>(false);
    useEffect(() => {
        (async () => {})();
    }, []);
    const onResult = (result: GwFlowStepResult) => {
        const { rawScanResult, ...rest } = result;
        debugDataWithModal('', {
            ...rest,
        });
    };
    const as = {
        faceMatch: true,
        faceSimilarity: 99.84066772460938,
        liveness: true,
        livenessDiagnostic: 'Live',
        dataValid: true,
        dataValidChecks: {
            emailData: {
                message: 'Success.',
                success: true,
                valid: true,
                disposable: false,
                smtp_score: 3,
                overall_score: 4,
                first_name: 'Pablo',
                generic: false,
                common: true,
                dns_valid: true,
                honeypot: false,
                deliverability: 'high',
                frequent_complainer: false,
                spam_trap_score: 'none',
                catch_all: false,
                timed_out: false,
                suspect: false,
                recent_abuse: false,
                fraud_score: 45,
                suggested_domain: 'N/A',
                leaked: false,
                domain_age: {
                    human: '28 years ago',
                    timestamp: 808286400,
                    iso: '1995-08-13T00:00:00-04:0...',
                },
                first_seen: {
                    human: '2 years ago',
                    timestamp: 1618328753,
                    iso: '2021-04-13T11:45:53-04:0...',
                },
                sanitized_email: 'pablopenen@gmail.com',
                domain_velocity: 'Enterprise Mini or highe...',
                user_activity: 'Enterprise L4+ required.',
                associated_names: {
                    status: 'Enterprise Plus or highe...',
                    names: {},
                },
                associated_phone_numbers: {
                    status: 'Enterprise Plus or highe...',
                    phone_numbers: {},
                },
                request_id: 'GwoX6wo2kg',
            },
            ipData: {
                success: true,
                message: 'Success',
                fraud_score: 100,
                country_code: 'N/A',
                region: 'N/A',
                city: 'N/A',
                ISP: 'Reserved',
                ASN: 3,
                organization: 'Reserved',
                is_crawler: false,
                timezone: '',
                mobile: false,
                host: 'localhost',
                proxy: true,
                vpn: true,
                tor: false,
                active_vpn: false,
                active_tor: false,
                recent_abuse: true,
                bot_status: true,
                connection_type: 'Premium required.',
                abuse_velocity: 'Premium required.',
                zip_code: 'N/A',
                latitude: 0,
                longitude: 0,
                request_id: 'GwoX7lKsCL',
            },
            phoneData: {
                message: 'Phone is valid.',
                success: true,
                formatted: '+59899223972',
                local_format: '099 223 972',
                valid: true,
                fraud_score: 0,
                recent_abuse: false,
                VOIP: false,
                prepaid: false,
                risky: false,
                active: true,
                carrier: 'Antel (Ancel)',
                line_type: 'Wireless',
                country: 'UY',
                city: '19 De Abril',
                zip_code: 'N/A',
                region: 'Uruguay',
                dialing_code: 598,
                active_status: 'N/A',
                sms_domain: 'N/A',
                associated_email_addresses: {
                    status: 'Enterprise Plus or highe...',
                    emails: {},
                },
                user_activity: 'Enterprise L4+ required.',
                mnc: 'N/A',
                mcc: 'N/A',
                leaked: true,
                spammer: false,
                request_id: 'GwoX8bHrSx',
                name: 'N/A',
                timezone: 'America/Montevideo',
                do_not_call: false,
                sms_email: 'N/A',
            },
        },
        blacklisted: false,
        blacklistFaceId: {},
        timestamp: '2023-09-14T19:01:53.346Z',
        transactionId: '9907e586-539d-4f4b-bbc6-...',
        status: 'VALIDATED',
    };
    const samples: {
        title: string;
        props: GwFlowValidateProps;
    }[] = [
        {
            title: 'Default',
            props: {
                options: {
                    showSummary: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: currentResultForValidate,
            },
        },
        {
            title: 'with non summary',
            props: {
                options: { showSummary: false },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: currentResultForValidate,
            },
        },
        {
            title: 'with auto continue',
            props: {
                options: { showSummary: false, autoContinueSeconds: 8 },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: currentResultForValidate,
            },
        },
    ];
    return (
        <Collapse destroyInactivePanel={true}>
            {samples.map(({ title, props }, index) => {
                return (
                    <Collapse.Panel header={title} key={`fragment-${index}`}>
                        <ConfigProvider
                            defaultTransactionId={
                                '9907e586-539d-4f4b-bbc6-579a24517000'
                            }
                            defaultClientId={25}
                            onLoad={async () => {
                                try {
                                    await GwService.update({index: 1,
                                        result: currentResultForValidate,
                                    });
                                    setReady(true);
                                } catch (e) {
                                    console.error(e);
                                }
                            }}
                            config={{}}
                        >
                            {ready ? <GwFlowValidate {...props} /> : null}
                        </ConfigProvider>
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    );
}
