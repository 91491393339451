import React from 'react';
import { Link, Route, useParams } from 'react-router-dom';
import { Divider } from 'antd';
import DebugGwFlowDocumentCapture from './DebugGwFlowDocumentCapture';
import DebugGwFlowFaceCapture from './DebugGwFlowFaceCapture';
import DebugGwFlowDocumentTypeSelect from './DebugGwFlowDocumentTypeSelect';
import DebugGwFlowPersonalInfo from './DebugGwFlowPersonalInfo';
import DebugGwFlow from './DebugGwFlow';
import DebugGwFlowValidate from './DebugGwFlowValidate';
import DebugGwFlowOtp from './DebugGwFlowOtp';
import DebugGwFlowResult from './DebugGwFlowResult';
import DebugGwFlowApp from './DebugGwFlowApp';
import DebugGwFlowUploads from './DebugGwFlowUploads';

import { ConfigProvider } from '../components/ConfigContext';
import DebugCommon from './DebugCommon';
import DebugGwFlowWelcome from './DebugGwFlowWelcome';
import './DebugComponents.css';

export default DebugComponents;

const components: [string, any][] = [
    ['GwFlowApp', DebugGwFlowApp],
    ['GwFlow', DebugGwFlow],
    ['GwFlowWelcome', DebugGwFlowWelcome],
    ['GwFlowPersonalInfo', DebugGwFlowPersonalInfo],
    ['GwFlowUploads', DebugGwFlowUploads],
    ['GwFlowOtp', DebugGwFlowOtp],
    ['GwFlowDocumentTypeSelect', DebugGwFlowDocumentTypeSelect],
    ['GwFlowDocumentCapture', DebugGwFlowDocumentCapture],
    ['GwFlowFaceCapture', DebugGwFlowFaceCapture],
    ['GwFlowValidate', DebugGwFlowValidate],
    ['GwFlowResult', DebugGwFlowResult],
    ['Common', DebugCommon],
];

export type DebugComponentsProps = {};

function DebugComponents() {
    const { componentName } = useParams<{ componentName: string }>();

    return (
        <ConfigProvider defaultLocale={'en'}>
            <div className="DebugComponents">
                {!componentName &&
                    components.map(([name], index) => (
                        <div key={index}>
                            <Link to={`/debugComponents/${name}`}>{name}</Link>
                        </div>
                    ))}
                {componentName ? (
                    <div>
                        <Link to={`/debugComponents`}>Back</Link>
                        <Divider />
                    </div>
                ) : null}
                {components.map(([name, component], index) => (
                    //@ts-ignore
                    <Route
                        key={index}
                        path={`/debugComponents/${name}`}
                        component={component}
                    />
                ))}
            </div>
        </ConfigProvider>
    );
}
