import React from 'react';
import './Button.css';
import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';

export default Button;

export type ButtonProps = AntButtonProps & {};

function Button({ type, ...rest }: ButtonProps) {
    return <AntButton className="Button" type={type} size="large" {...rest} />;
}
