import React from 'react';
import { Space, Typography } from 'antd';
import Icon, { CameraOutlined } from '@ant-design/icons';
import { imgSelfieSvg } from '../../common/images';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../common/Button';

export default GwFlowFaceCaptureTips;

export type GwFlowDocumentCaptureTipsProps = {
    onClick: () => void;
};

function GwFlowFaceCaptureTips({ onClick }: GwFlowDocumentCaptureTipsProps) {
    const { t } = useTranslation();
    return (
        <div className="GwFlowDocumentCaptureTips">
            <Space direction="vertical" size={20}>
                <Icon component={imgSelfieSvg} />
                <div>
                    <Trans i18nKey="Posiciona <1>tu rostro en el centro de la cámara</1> y espera a que tomemos una selfie por ti.">
                        Posiciona{' '}
                        <strong>tu rostro en el centro de la cámara</strong> y
                        espera a que tomemos una selfie por ti.
                    </Trans>{' '}
                    {t(
                        'No necesitas hacer ningún movimiento ni gesto, así de sencillo.'
                    )}{' '}
                    <Trans i18nKey="Recuerda <1>no usar gafas de sol ni gorra</1>.">
                        Recuerda <strong>no usar gafas de sol ni gorras</strong>
                        .
                    </Trans>
                </div>
                <Button type="primary" onClick={onClick}>
                    <CameraOutlined /> {t('Tomar selfie')}
                </Button>
                <Typography.Text type="secondary">
                    <small>
                        {t(
                            'Al continuar, acepto que {{company}} analiza mis datos biométricos faciales con el propositivo de verificar mi identidad y que inmediatamente finalizado el proceso elimina por completo dicha información',
                            { company: 'la empresa' }
                        )}
                    </small>
                </Typography.Text>
            </Space>
        </div>
    );
}
