import React from 'react';
import DebugComponents from './DebugComponents';
import { Modal } from 'antd';
//@ts-ignore
import truncateObject from 'gw-api/truncateObject';

export default DebugComponentsPage;
export type DebugComponentsPageProps = {};

function DebugComponentsPage({ ...rest }: DebugComponentsPageProps) {
    return <DebugComponents />;
}

export function debugMethodWithModal(
    title: string,
    data: any,
    error?: any,
    resolveData?: any
) {
    return new Promise<any>((resolve, reject) => {
        Modal.confirm({
            title,
            width: '90%',
            content: (
                <div>
                    <small>
                        <pre>
                            {JSON.stringify(truncateObject(data), undefined, 2)}
                        </pre>
                    </small>
                </div>
            ),
            onCancel: () => reject(error || new Error('request error')),
            onOk: () => resolve(resolveData || {}),
            cancelText: 'Simulate error',
            okText: 'Simulate success',
        });
    });
}
export function debugDataWithModal(title: string, data: any) {
    Modal.info({
        title,
        width: '90%',
        content: (
            <small>
                <pre>{JSON.stringify(truncateObject(data), undefined, 2)}</pre>
            </small>
        ),
    });
}
export function debugErrorWithModal(error: any) {
    console.error(error);
    Modal.error({
        content: <div>{error?.message || error}</div>,
    });
}
