import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detectBrowserLanguage from 'detect-browser-language';
import es from './lang/es.json';
import en from './lang/en.json';
import pt from './lang/pt.json';
// the translations

export default function getI18n() {
    const resources = {
        es,
        en,
        pt,
    };
    let [locale] = detectBrowserLanguage().split('-');
    if (window.SORTVARS?.config?.uiConfig?.locale) {
        locale = window.SORTVARS.config.uiConfig.locale;
    }
    if (window.SORTVARS?.locale) {
        locale = window.SORTVARS.locale;
    }
    //default to 'es'
    locale = ['en', 'es', 'pt'].includes(locale) ? locale : 'es';
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: locale,

            keySeparator: false, // we do not use keys in form messages.welcome

            interpolation: {
                escapeValue: false, // react already safes from xss
            },
        });

    return i18n;
}
