import React from 'react';
import TitleText from './common/TitleText';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import StepContent from './common/StepContent';
import { useConfigContext } from './components/ConfigContext';

export type ProcessFinishedMessageProps = {
    clientData?: any;
    hasVideo?: boolean;
    extra?: any;
};

export default function ProcessFinishedMessage({
    extra,
}: ProcessFinishedMessageProps) {
    const { t } = useTranslation();
    const { config } = useConfigContext();

    return (
        <StepContent
            header={
                <TitleText size="large">
                    {t('El proceso ha finalizado')}
                </TitleText>
            }
            body={
                <Space direction="vertical" align="center">
                    <Space
                        style={{
                            fontSize: '96px',
                            color: config?.uiConfig?.primaryColor,
                        }}
                    ></Space>
                    {t('El proceso de identificacion ya ha finalizado')}
                    {extra}
                </Space>
            }
        />
    );
}
