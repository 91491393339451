import React, { useState } from 'react';

export const StyleThemeContext = React.createContext({});

export const StyleThemeProvider = ({ children, initialStyleTheme = {} }) => {
    const [styleTheme, setStyleTheme] = useState(initialStyleTheme);

    const styleThemeContext = {
        styleTheme,
        setStyleTheme,
    };
    return (
        <StyleThemeContext.Provider value={styleThemeContext}>
            {children}
        </StyleThemeContext.Provider>
    );
};
