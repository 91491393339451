import React from 'react';
import './LoadingWrapper.css';
import { Spin } from 'antd';
import classNames from 'classnames';

export default LoadingWrapper;

export type LoadingWrapperProps = {
    className?: string;
    children?: React.ReactNode;
    loading?: boolean;

    headerExtra?: React.ReactNode;
    onBackClick?: () => void;
};

function LoadingWrapper({ className, children, loading }: LoadingWrapperProps) {
    return (
        <div className={classNames('LoadingWrapper', className)}>
            <div style={{ width: '100%', minHeight: '300px' }}>
                <Spin spinning={loading}>{children} </Spin>
            </div>
        </div>
    );
}
