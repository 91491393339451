import React, { useEffect, useState } from 'react';
import './CardFlip.css';
import Icon from '@ant-design/icons';
import classNames from 'classnames';

export default CardFlip;

export type CardFlipProps = {
    className?: string;
    dontFlip?: boolean;
    isFlipped?: boolean;
    size?: 'small' | 'medium';
};

function CardFlip({
    className,
    dontFlip,
    isFlipped,
    size = 'medium',
}: CardFlipProps) {
    const [flipped, setFlipped] = useState<boolean>(!!isFlipped);
    useEffect(() => {
        if (!dontFlip) {
            setTimeout(() => {
                setFlipped(true);
            }, 250);
        }
    }, []);

    useEffect(() => {
        if (isFlipped !== undefined) {
            setFlipped(isFlipped);
        }
    }, [isFlipped]);
    return (
        <div
            className={classNames('flip-container', className, {
                hover: flipped,
                'flip-container--small': size === 'small',
            })}
        >
            <div className="flipper">
                <div className="front">
                    <Icon
                        component={() => (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={size === 'small' ? '40' : '70'}
                                height={size === 'small' ? '40' : '70'}
                                viewBox={'0 0 48 48'}
                            >
                                <g
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinejoin="round"
                                    strokeWidth="4"
                                >
                                    <rect
                                        width="40"
                                        height="32"
                                        x="4"
                                        y="8"
                                        rx="2"
                                    />
                                    <path d="M17 25a4 4 0 1 0 0-8a4 4 0 0 0 0 8Z" />
                                    <path
                                        strokeLinecap="round"
                                        d="M23 31a6 6 0 0 0-12 0m17-11h8m-6 8h6"
                                    />
                                </g>
                            </svg>
                        )}
                    />
                </div>
                <div className="back">
                    <Icon
                        component={() => (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={size === 'small' ? '40' : '70'}
                                height={size === 'small' ? '40' : '70'}
                                viewBox={'0 0 48 48'}
                            >
                                <g
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinejoin="round"
                                    strokeWidth="4"
                                >
                                    <path d="M42 8H6a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2Z" />
                                    <path d="M36 16h-8v8h8v-8Z" />
                                    <path
                                        strokeLinecap="round"
                                        d="M12 32h24M12 16h6m-6 8h6"
                                    />
                                </g>
                            </svg>
                        )}
                    />
                </div>
            </div>
        </div>
    );
}
