import React from 'react';
import { Row, Col, Form, FormInstance } from 'antd';
import { GwFlowPersonalInfoFieldMap } from './GwFlowPersonalInfo';
import { useTranslation } from 'react-i18next';
import PhoneNumberInput from '../../common/PhoneNumberInput';
import { GwFlowPersonalInfoFieldType } from 'gw-api/types';
import Input from '../../common/Input';

export default GwFlowPersonalInfoContactForm;

export type GwFlowPersonalInfoContactFormProps = {
    form: FormInstance;
    fieldsMap: GwFlowPersonalInfoFieldMap;
    defaultCountryCode?: string;
    useGeolocation?: boolean;
};

function GwFlowPersonalInfoContactForm({
    fieldsMap,
    useGeolocation,
    defaultCountryCode,
}: GwFlowPersonalInfoContactFormProps) {
    const { t } = useTranslation();
    return (
        <Row gutter={20} className="GwFlowPersonalInfoContactForm">
            {Object.values(fieldsMap)
                .filter((field) => field.show)
                .map((field) => {
                    const { type, show, disabled, ...restField } = field;
                    if (type === GwFlowPersonalInfoFieldType.address) {
                        return (
                            <Col xs={24} sm={24} md={24} key="address">
                                <Form.Item name="address" {...restField}>
                                    <Input.TextArea
                                        placeholder={t(
                                            'Ingresa tu dirección completa'
                                        )}
                                        autoSize={{
                                            minRows: 2,
                                        }}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (type === GwFlowPersonalInfoFieldType.email) {
                        return (
                            <Col xs={24} sm={24} md={24} key="email">
                                <Form.Item name="email" {...restField}>
                                    <Input
                                        placeholder={t('Ingresa tu email')}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (type === GwFlowPersonalInfoFieldType.phone) {
                        return (
                            <Col xs={24} sm={24} md={24} key="phone">
                                <Form.Item name="phone" {...restField}>
                                    <PhoneNumberInput
                                        defaultCountryCode={defaultCountryCode}
                                        placeholder={t(
                                            'Ingresa tu número de movil'
                                        )}
                                        disabled={disabled}
                                        useGeolocation={useGeolocation}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (
                        type ===
                        GwFlowPersonalInfoFieldType.taxIdentificationNumber
                    ) {
                        return (
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                key="taxIdentificationNumber"
                            >
                                <Form.Item
                                    name="taxIdentificationNumber"
                                    {...restField}
                                >
                                    <Input
                                        placeholder={t(
                                            'Ingresa tu número de identificación de impuesto'
                                        )}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (type === GwFlowPersonalInfoFieldType.cpfNumber) {
                        return (
                            <Col xs={24} sm={24} md={24} key="cpfNumber">
                                <Form.Item name="cpfNumber" {...restField}>
                                    <Input
                                        placeholder={t(
                                            'Ingresa tu número de CPF'
                                        )}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    }
                })}
        </Row>
    );
}
