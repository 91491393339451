import { Modal, ModalProps } from 'antd';

export interface ErrorWithRetryModalProps extends ModalProps {
    onRetry?: () => void;
}
export function displayErrorWithRetry(
    error: any,
    { onRetry, ...rest }: ErrorWithRetryModalProps,
    t: any
) {
    return Modal.confirm({
        title: error.message,
        cancelText: t('Cancelar'),
        okText: t('Reintentar'),
        onOk: onRetry,
        ...rest,
    });
}
