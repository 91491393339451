import React from 'react';
import { Collapse, Modal } from 'antd';
import GwFlowDocumentTypeSelect, {
    GwFlowDocumentTypeSelectProps,
} from '../components/GwFlowDocumentTypeSelect/GwFlowDocumentTypeSelect';
import { debugDataWithModal } from './DebugComponentsPage';
import {
    DocumentCode,
    GwFlowDocumentTypeSelectSelectorType,
} from 'gw-api/types';
import { GwFlowStepResult } from '../components/GwFlow';

export default DebugGwFlowDocumentTypeSelect;
export type DebugGwFlowDocumentTypeSelectProps = {};

function DebugGwFlowDocumentTypeSelect({
    ...rest
}: DebugGwFlowDocumentTypeSelectProps) {
    const onResult = (result: GwFlowStepResult) => {
        const { rawScanResult, ...rest } = result;
        debugDataWithModal('', {
            ...rest,
        });
    };
    const samples: {
        title: string;
        props: GwFlowDocumentTypeSelectProps;
    }[] = [
        {
            title: 'Default',
            props: {
                options: {
                    availableDocuments: [
                        DocumentCode.ID_CARD,
                        DocumentCode.PASSPORT,
                        // 'DRIVING_LICENSE',
                    ],
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
        {
            title: 'with default values',
            props: {
                options: {
                    availableDocuments: [
                        DocumentCode.ID_CARD,
                        DocumentCode.PASSPORT,
                        // 'DRIVING_LICENSE',
                    ],
                    defaultCountryCode: 'URY',
                    defaultDocumentCode: DocumentCode.ID_CARD,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
        {
            title: 'with default values and dropdown',
            props: {
                options: {
                    availableDocuments: [
                        DocumentCode.ID_CARD,
                        DocumentCode.PASSPORT,
                        // 'DRIVING_LICENSE',
                    ],
                    defaultDocumentCode: DocumentCode.ID_CARD,
                    selectorType: GwFlowDocumentTypeSelectSelectorType.DROPDOWN,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
        {
            title: 'with showCountrySelector',
            props: {
                options: {
                    availableDocuments: [
                        DocumentCode.ID_CARD,
                        DocumentCode.PASSPORT,
                        // 'DRIVING_LICENSE',
                    ],
                    defaultDocumentCode: DocumentCode.ID_CARD,
                    showCountrySelector: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
        {
            title: 'with showCountrySelector and default country',
            props: {
                options: {
                    availableDocuments: [
                        DocumentCode.ID_CARD,
                        DocumentCode.PASSPORT,
                        // 'DRIVING_LICENSE',
                    ],
                    defaultCountryCode: 'URY',
                    defaultDocumentCode: DocumentCode.ID_CARD,
                    showCountrySelector: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
    ];
    return (
        <Collapse destroyInactivePanel={true} defaultActiveKey={'fragment-2'}>
            {samples.map(({ title, props }, index) => {
                return (
                    <Collapse.Panel header={title} key={`fragment-${index}`}>
                        <GwFlowDocumentTypeSelect {...props} />
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    );
}
