import React, { forwardRef } from 'react';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import './Select.css';

export type SelectProps = AntSelectProps & {};

function Select({ ...rest }: SelectProps) {
    return <AntSelect className="Select" {...rest} />;
}

const SelectForwardRef = forwardRef(Select);

SelectForwardRef.Option = AntSelect.Option;

export default SelectForwardRef;
