import React, { Fragment, useContext, useMemo, useState } from 'react';
import './GwFlow.css';
import ContentBox from '../common/ContentBox';
import { Button, Modal, Steps, Tabs, Popconfirm } from 'antd';
import GwFlowDocumentTypeSelect, {
    GwFlowDocumentTypeSelectResult,
} from './GwFlowDocumentTypeSelect/GwFlowDocumentTypeSelect';
import GwFlowDocumentCapture from './GwFlowDocumentCapture/GwFlowDocumentCapture';
import GwFlowPersonalInfo, {
    GwFlowPersonalInfoResult,
} from './GwFlowPersonalInfo/GwFlowPersonalInfo';
import GwFlowFaceCapture, {
    GwFlowFaceCaptureProviderResult,
} from './GwFlowFaceCapture/GwFlowFaceCapture';
import GwFlowValidate, {
    GwFlowValidateResult,
} from './GwFlowValidate/GwFlowValidate';
import GwFlowWelcome, {
    GwFlowWelcomeResult,
} from './GwFlowWelcome/GwFlowWelcome';
import { DebugContext } from '../common/Debug';
import classNames from 'classnames';
import {
    LineOutlined,
    CameraOutlined,
    CloseOutlined,
    CloudSyncOutlined,
    FileTextOutlined,
    FormOutlined,
    IdcardOutlined,
    MailOutlined,
    MobileOutlined,
    SecurityScanOutlined,
} from '@ant-design/icons';
import FloatingContent from '../common/FloatingContent';
import { isMobile } from 'react-device-detect';
import UseMyMobile from '../common/UseMyMobile';
import SessionTransferedBackMessage from '../SessionTransferedBackMessage';
import Space from '../common/Space';
import GwFlowUploads, {
    GwFlowUploadsResult,
} from './GwFlowUploads/GwFlowUploads';
import {
    GwConfig,
    GwConfigStep,
    GwConfigStepOptions,
    GwConfigStepType,
    GwFlowDocumentCaptureProviderResult,
} from 'gw-api/types';
import GwFlowResult, { GwFlowResultResult } from './GwFlowResult/GwFlowResult';
import GwFlowOtp, { GwFlowOtpResult } from './GwFlowOtp/GwFlowOtp';
import { useTranslation } from 'react-i18next';

export default GwFlow;

export type GwFlowProps = {
    config: GwConfig;
    onPartialResult?: (
        result: GwFlowGlobalResult,
        step: GwConfigStep<GwConfigStepOptions>,
        index: number
    ) => void;
    onFinish?: (result: GwFlowGlobalResult) => void;
    activeStepIndex?: number;
    flowInOtherDevice?: boolean;
    forceMobile?: boolean;
    enableMobile?: boolean;
    initialResult?: GwFlowGlobalResult;
};

export interface GwFlowStepResult {
    [key: string]: any;
}
export type GwFlowGlobalResult = {
    [GwConfigStepType.WELCOME]?: GwFlowWelcomeResult;
    [GwConfigStepType.UPLOADS]?: GwFlowUploadsResult;
    [GwConfigStepType.DOCUMENT_TYPE_SELECT]?: GwFlowDocumentTypeSelectResult;
    [GwConfigStepType.DOCUMENT_CAPTURE]?: GwFlowDocumentCaptureProviderResult;
    [GwConfigStepType.PERSONAL_INFO]?: GwFlowPersonalInfoResult;
    [GwConfigStepType.OTP_SMS]?: GwFlowOtpResult;
    [GwConfigStepType.OTP_EMAIL]?: GwFlowOtpResult;
    [GwConfigStepType.SELFIE_CAPTURE]?: GwFlowFaceCaptureProviderResult;
    [GwConfigStepType.VALIDATE]?: GwFlowValidateResult;
    [GwConfigStepType.RESULT]?: GwFlowResultResult;
};
export interface GwFlowStepProps {
    onResult?: (result: GwFlowStepResult) => void;
    onFinish?: (result: GwFlowStepResult) => void;
    onCancel?: () => void;
    onError?: (error: any) => void;
    options?: GwConfigStepOptions;
    currentResult?: { [key in GwConfigStepType]?: any };
    showBackButton?: boolean;
    initialResult?: GwFlowGlobalResult;
}

function GwFlow({
    config,
    activeStepIndex,
    onFinish,
    onPartialResult,
    flowInOtherDevice,
    forceMobile,
    enableMobile,
    initialResult,
}: GwFlowProps) {
    const { logError } = useContext(DebugContext);
    const { t } = useTranslation();
    const [needsTransfer, setNeedsTransfer] = useState<boolean>(false);
    const [cursor, setCursor] = useState<number>(() => {
        return (activeStepIndex || 0) <= config?.steps.length - 1
            ? activeStepIndex || 0
            : config?.steps.length - 1;
    });
    const [results, setResults] = useState<GwFlowGlobalResult>(
        initialResult || {}
    );
    const [useMyMobileOpen, settUseMyMobileOpen] = useState<boolean>(false);
    const handleResult = async (
        result: GwFlowStepResult,
        step: GwConfigStep<GwConfigStepOptions>,
        index: number
    ) => {
        const newResult = {
            ...results,
            [step.type as GwConfigStepType]: result,
        };
        await onPartialResult?.(
            { [step.type as GwConfigStepType]: result },
            step,
            index
        );
        console.log(newResult);
        setResults(newResult);
        if (result.status === 'error') {
            const resultStepIndex = config.steps.findIndex(
                (step) => step.type === GwConfigStepType.RESULT
            );
            setCursor(resultStepIndex);
        } else {
            const newCursor = cursor + 1;
            if (newCursor > config.steps.length - 1) {
                if (!result.transfer) {
                    await onFinish?.(newResult);
                } else {
                    setNeedsTransfer(true);
                }
            } else {
                switch (step.type) {
                    default:
                        setCursor(cursor + 1);
                }
            }
        }
    };

    const handleCancel = (s: GwConfigStep<GwConfigStepOptions>) => {
        const newCursor = cursor - 1;
        setCursor(newCursor);
    };

    const handleCancelByUser = () => {
        setCursor(config.steps.length - 1);
    };
    const handleError = (error: any, s: any) => {
        Modal.error({
            content: (
                <div>
                    {t('Ha ocurrido un error y el proceso no puede continuar')}
                </div>
            ),
        });
        logError(error.message);
        onFinish?.({ [GwConfigStepType.RESULT]: { error: error.message } });
    };
    const handleResultSentFromOtherDevice = (result: GwFlowGlobalResult) => {
        onFinish?.({ [GwConfigStepType.RESULT]: result });
    };

    const handleUseMyMobileOpenChange = (open: boolean) => {
        settUseMyMobileOpen(open);
    };

    const items = useMemo(
        () =>
            config.steps.map((s, index) => {
                return {
                    label: '',
                    key: `${s.type}-${index}`,
                    children: React.cloneElement<GwFlowStepProps>(
                        getFlowStepContent(s),
                        {
                            onResult: (result) =>
                                handleResult(result, s, index),
                            onCancel: () => handleCancel(s),
                            onError: (error) => handleError(error, s),
                            options: s.options,
                            currentResult: results as {
                                [key in GwConfigStepType]?: any;
                            },
                            showBackButton: false,
                            // showBackButton:
                            //     index !== 0 &&
                            //     index !== config.steps.length - 1,
                        }
                    ),
                };
            }),
        [config, results, cursor]
    );
    return (
        <div
            className="ValidationFlow"
            style={{
                visibility:
                    (forceMobile && !isMobile) || useMyMobileOpen
                        ? 'hidden'
                        : 'visible',
            }}
        >
            <ContentBox style={{ position: 'relative' }}>
                {isMobile && needsTransfer ? (
                    <SessionTransferedBackMessage />
                ) : (
                    <Fragment>
                        <ContentBox.Header>
                            <Space wide={true} direction="vertical" size={70}>
                                <img
                                    src={'goboarding.png'}
                                    style={{ width: '125px' }}
                                />
                                <Space
                                    wide={true}
                                    justify={'center'}
                                    className={'GwFlow-steps-space'}
                                >
                                    {/*<div>&nbsp;&nbsp;&nbsp;&nbsp;</div>*/}
                                    {![
                                        GwConfigStepType.VALIDATE,
                                        GwConfigStepType.RESULT,
                                    ].includes(config.steps[cursor]?.type) ? (
                                        <Space size={5}>
                                            {items.map((item, index) => {
                                                return (
                                                    <Button
                                                        key={index}
                                                        type={
                                                            index === cursor
                                                                ? 'primary'
                                                                : 'default'
                                                        }
                                                        className={classNames(
                                                            'GwFlow-step_button',
                                                            {
                                                                'GwFlow-step_button--current':
                                                                    index <
                                                                    cursor,
                                                            }
                                                        )}
                                                    >
                                                        &nbsp;&nbsp;&nbsp;
                                                    </Button>
                                                );
                                            })}
                                        </Space>
                                    ) : null}

                                    {/*<Steps*/}
                                    {/*    size="small"*/}
                                    {/*    className={'GwFlow-steps'}*/}

                                    {/*    items={items.map((item, index) => ({*/}
                                    {/*        // onClick: () => handleStepClick(item, index),*/}
                                    {/*        className: classNames({*/}
                                    {/*            'GwFlow-step--current':*/}
                                    {/*                index === cursor,*/}
                                    {/*        }),*/}
                                    {/*        icon: item.icon,*/}
                                    {/*    }))}*/}
                                    {/*    current={cursor}*/}
                                    {/*    responsive={false}*/}
                                    {/*    progressDot={true}*/}
                                    {/*/>*/}
                                    {/*<div style={{marginLeft: '-53px'}}>*/}
                                    {/*    <Popconfirm*/}
                                    {/*      title={'Are you sure?'}*/}
                                    {/*      onConfirm={handleCancelByUser}*/}
                                    {/*    >*/}
                                    {/*        <Button type="link">*/}
                                    {/*            Close*/}
                                    {/*        </Button>*/}
                                    {/*    </Popconfirm>*/}
                                    {/*</div>*/}
                                </Space>
                            </Space>
                        </ContentBox.Header>
                        <ContentBox.Body>
                            <Tabs
                                items={items}
                                activeKey={`${config.steps[cursor].type}-${cursor}`}
                                renderTabBar={() => null}
                                destroyInactiveTabPane={true}
                                style={{ width: '100%' }}
                            />
                        </ContentBox.Body>
                    </Fragment>
                )}

                {!isMobile &&
                enableMobile &&
                ![GwConfigStepType.VALIDATE, GwConfigStepType.RESULT].includes(
                    config.steps[cursor]?.type
                ) ? (
                    // <FloatingContent>
                    <Space>
                        <UseMyMobile
                            defaultVisible={forceMobile}
                            closable={!forceMobile}
                            onOpenChange={handleUseMyMobileOpenChange}
                        />
                    </Space>
                ) : // </FloatingContent>
                null}
            </ContentBox>
        </div>
    );
}

export function getFlowStepContent(step: GwConfigStep<GwConfigStepOptions>) {
    switch (step.type) {
        case GwConfigStepType.WELCOME:
            return <GwFlowWelcome />;
        case GwConfigStepType.DOCUMENT_TYPE_SELECT:
            return <GwFlowDocumentTypeSelect />;
        case GwConfigStepType.DOCUMENT_CAPTURE:
            return <GwFlowDocumentCapture />;
        case GwConfigStepType.SELFIE_CAPTURE:
            return <GwFlowFaceCapture />;
        case GwConfigStepType.PERSONAL_INFO:
            return <GwFlowPersonalInfo />;
        case GwConfigStepType.UPLOADS:
            return <GwFlowUploads />;
        case GwConfigStepType.OTP_SMS:
            return <GwFlowOtp type="sms" />;
        case GwConfigStepType.OTP_EMAIL:
            return <GwFlowOtp type="email" />;
        case GwConfigStepType.VALIDATE:
            return <GwFlowValidate />;
        case GwConfigStepType.RESULT:
            return <GwFlowResult />;
        default:
            return <div>No step</div>;
    }
}
export function getFlowStepIcon(step: GwConfigStep<GwConfigStepOptions>) {
    switch (step.type) {
        case GwConfigStepType.WELCOME:
            return <FileTextOutlined />;
        case GwConfigStepType.DOCUMENT_TYPE_SELECT:
            return <IdcardOutlined />;
        case GwConfigStepType.DOCUMENT_CAPTURE:
            return <CameraOutlined />;
        case GwConfigStepType.SELFIE_CAPTURE:
            return <CameraOutlined />;
        case GwConfigStepType.PERSONAL_INFO:
            return <FormOutlined />;
        case GwConfigStepType.UPLOADS:
            return <FormOutlined />;
        case GwConfigStepType.OTP_SMS:
            return <MobileOutlined />;
        case GwConfigStepType.OTP_EMAIL:
            return <MailOutlined />;
        case GwConfigStepType.VALIDATE:
            return <SecurityScanOutlined />;
        case GwConfigStepType.RESULT:
            return <CloudSyncOutlined />;
        default:
            return undefined;
    }
}
