import React, { CSSProperties, ReactNode } from 'react';
import './ContentBox.css';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

export default ContentBox;

export type ContentBoxHeaderProps = {
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
};
ContentBox.Header = ({ children, className, style }: ContentBoxHeaderProps) => {
    return (
        <div
            className={classNames('ContentBox_header', className)}
            style={style}
        >
            {children}
        </div>
    );
};

export type ContentBoxBodyProps = {
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
};
ContentBox.Body = ({ children, className, style }: ContentBoxBodyProps) => {
    return (
        <div className={classNames('ContentBox_body', className)} style={style}>
            {children}
        </div>
    );
};

export type ContentBoxFooterProps = {
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
};
ContentBox.Footer = ({ children, className, style }: ContentBoxFooterProps) => {
    return (
        <div
            className={classNames('ContentBox_footer', className)}
            style={style}
        >
            {children}
        </div>
    );
};

export type ContentBoxProps = {
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
};
function ContentBox({ children, className, style }: ContentBoxProps) {
    return (
        <div
            className={classNames(
                'ContentBox',
                { 'ContentBox--mobile': isMobile },
                className
            )}
            style={style}
        >
            {children}
        </div>
    );
}
