import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GwService from '../../services/GwService';
import { DebugContext } from '../../common/Debug';
import TitleText from '../../common/TitleText';
import StepContent from '../../common/StepContent';
import { Spin } from 'antd';
import Space from '../../common/Space';

export default GwFlowValidateDo;

export type GwFlowValidateDoProps = {
    onSuccess: (result: any) => void;
    onError: (error: any) => void;
};

function GwFlowValidateDo({ onSuccess, onError }: GwFlowValidateDoProps) {
    const { t } = useTranslation();
    const { logError, log } = useContext(DebugContext);
    const [validating, setValidating] = useState<boolean>(false);

    const validate = async () => {
        try {
            log('Validating');
            setValidating(true);
            const response = await GwService.validate();
            log('Validation success');
            onSuccess?.(response);
            setValidating(false);
        } catch (error) {
            setValidating(false);
            onError(error);
        }
        //     if (
        //         !deviceInfo.isMobile &&
        //         getQrDeviceTransfer(clientData) &&
        //         (error instanceof ApiError500Liveness ||
        //             error instanceof ApiError500AuthenticateFacial)
        //     ) {
        //         setSuggestDeviceTransfer(true);
        //         log('suggesting device change');
        //     } else {
        //         logError(error);
        //         callResult({
        //             result: WIZARD_STEP_RESULT_ERROR,
        //             message: error.message,
        //         });
        //     }
        // }
    };
    useEffect(() => {
        (async () => {
            await validate();
        })();
    }, []);
    return (
        <div className="GwFlowValidateDo">
            <StepContent
                header={
                    <TitleText size="large">
                        {t('Verificando identidad')}
                    </TitleText>
                }
                body={
                    <div>
                        <Space wide={true} direction="vertical">
                            {validating ? <Spin spinning={true} /> : null}

                            {t(
                                'Por favor espera mientras procesamos la información enviada. Esto nos puede tardar algunos segundos'
                            )}
                        </Space>
                    </div>
                }
            />
        </div>
    );
}
