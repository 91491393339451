import React from 'react';
import TitleText from './common/TitleText';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import {
    QrcodeOutlined,
    MobileOutlined,
    ArrowRightOutlined,
} from '@ant-design/icons';
import StepContent from './common/StepContent';
import { useConfigContext } from './components/ConfigContext';

export type SessionTransferedMessageProps = {
    clientData?: any;
    hasVideo?: boolean;
    clientConfiguration?: any;
    extra?: any;
};

export default function SessionTransferedMessage({
    extra,
}: SessionTransferedMessageProps) {
    const { t } = useTranslation();
    const { config } = useConfigContext();
    return (
        <StepContent
            header={
                <TitleText size="large">
                    {t('El proceso fue continuado en otro dispositivo')}
                </TitleText>
            }
            body={
                <Space direction="vertical" align="center">
                    <Space
                        style={{
                            fontSize: '96px',
                            color: config?.uiConfig?.primaryColor,
                        }}
                    >
                        <QrcodeOutlined />
                        <div style={{ fontSize: '48px' }}>
                            <ArrowRightOutlined />
                        </div>

                        <MobileOutlined />
                    </Space>
                    {t(
                        'Sigue las instrucciones en el otro dispositivo y no cierres esta página'
                    )}
                    {extra}
                </Space>
            }
        />
    );
}
