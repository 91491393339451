import React, { useEffect, useState } from 'react';
import { Collapse, Modal } from 'antd';
import { debugDataWithModal } from './DebugComponentsPage';
import { GwFlowStepResult } from '../components/GwFlow';
import GwFlowOtp, { GwFlowOtpProps } from '../components/GwFlowOtp/GwFlowOtp';
import { ConfigProvider } from '../components/ConfigContext';

export default DebugGwFlowOtp;
export type DebugGwFlowOtpProps = {};

function DebugGwFlowOtp({ ...rest }: DebugGwFlowOtpProps) {
    const [ready, setReady] = useState<boolean>(false);
    useEffect(() => {
        (async () => {})();
    }, []);
    const onResult = (result: GwFlowStepResult) => {
        const { rawScanResult, ...rest } = result;
        debugDataWithModal('', {
            ...rest,
        });
    };
    const samples: {
        title: string;
        props: GwFlowOtpProps;
    }[] = [
        {
            title: 'type email readonly',
            props: {
                type: 'email',
                options: {
                    defaultEmail: 'srpabliyo@gmail.com',
                    readonly: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
            },
        },
        {
            title: 'type sms readonly',
            props: {
                type: 'sms',
                options: {
                    defaultPhone: '+59899223972',
                    readonly: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
            },
        },
        {
            title: 'type email disabled',
            props: {
                type: 'email',
                options: {
                    defaultEmail: 'srpabliyo@gmail.com',
                    disabled: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
            },
        },
        {
            title: 'type sms disabled',
            props: {
                type: 'sms',
                options: {
                    defaultPhone: '+59899223972',
                    disabled: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
            },
        },
        {
            title: 'type email',
            props: {
                type: 'email',
                options: {},
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {
                    PERSONAL_INFO: {
                        personalInfo: {
                            email: 'srpabliyo@gmail.com',
                            // email: 'carlos@sort.com.uy',
                        },
                    },
                },
            },
        },
        {
            title: 'type sms',
            props: {
                type: 'sms',
                options: {},
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {
                    PERSONAL_INFO: {
                        personalInfo: {
                            // phone: '+59899001818',
                            phone: '+59899223972',
                        },
                    },
                },
            },
        },
        {
            title: 'type email with no current value',
            props: {
                type: 'email',
                options: {},
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
        {
            title: 'type sms with no current value',
            props: {
                type: 'sms',
                options: {
                    direction: 'vertical'
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
        {
            title: 'type sms with default country code',
            props: {
                type: 'sms',
                options: {
                    defaultCountryCode: 'BRA',
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
        {
            title: 'type sms with geolocation',
            props: {
                type: 'sms',
                options: {
                    useGeolocation: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },

        {
            title: 'type email with retries (1)',
            props: {
                type: 'email',
                options: {
                    retries: 1,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {
                    PERSONAL_INFO: {
                        personalInfo: {
                            email: 'srpabliyo@gmail.com',
                            // email: 'carlos@sort.com.uy',
                        },
                    },
                },
            },
        },
        {
            title: 'type sms with retries (1)',
            props: {
                type: 'sms',
                options: {
                    retries: 1,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {
                    PERSONAL_INFO: {
                        personalInfo: {
                            // phone: '+59899001818',
                            phone: '+59899223972',
                        },
                    },
                },
            },
        },
    ];
    return (
        <Collapse destroyInactivePanel={true}>
            {samples.map(({ title, props }, index) => {
                return (
                    <Collapse.Panel header={title} key={`fragment-${index}`}>
                        <ConfigProvider
                            defaultTransactionId={
                                '9907e586-539d-4f4b-bbc6-579a24517000'
                            }
                            defaultClientId={25}
                            onLoad={async () => {
                                setReady(true);
                            }}
                            config={{}}
                        >
                            {ready ? <GwFlowOtp {...props} /> : null}
                        </ConfigProvider>
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    );
}
