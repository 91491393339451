import './Image.css';
import React from 'react';
import classNames from 'classnames';

export default Image;

function Image({ className, src, alt, ...rest }) {
    return (
        <img
            className={classNames('Image', className)}
            src={src}
            alt={alt || src}
            {...rest}
        ></img>
    );
}
