import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function useRetries(initialRetries?: number) {
    const { t } = useTranslation();
    // Use null to indicate infinite retries
    const [retriesLeft, setRetriesLeft] = useState<number | null>(
        initialRetries === undefined || initialRetries === 0
            ? null
            : initialRetries
    );

    // Function to consume a retry, returns false if no retries are left (for finite retries)
    const consumeRetry = (): boolean => {
        setRetriesLeft((prevRetries) => {
            // If retries are infinite, just return null
            if (prevRetries === null) {
                return null;
            }
            // If retries are finite, decrement and check if any are left
            return prevRetries > 0 ? prevRetries - 1 : 0;
        });

        // Return true if infinite retries or if any retries are left
        return retriesLeft === null || retriesLeft > 0;
    };

    const resetRetries = () => {
        setRetriesLeft(
            initialRetries === undefined || initialRetries === 0
                ? null
                : initialRetries
        );
    };

    const getRetryError = (throwError?: boolean): Error | null => {
        if (retriesLeft === 0) {
            const error = new Error(t('No quedan mas reintentos'));
            if (throwError) {
                throw error;
            }
            return error;
        }
        return null;
    };

    return { retriesLeft, consumeRetry, resetRetries, getRetryError };
}

export default useRetries;
