import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { parse as parseQueryString } from 'query-string';
import { DebugProvider } from './common/Debug';

const queryObject = parseQueryString(window.location.search);
const debug = queryObject.debug === '1';
const enableError = queryObject.enableError === '1';

if (process.env.NODE_ENV === 'development') {
    (async () => {
        try {
            const isLoggedIn = localStorage.getItem('isLoggedIn');

            let linkToIndex = '/';
            if (isLoggedIn !== 'true') {
                // request for obtaining the initial otp and transaction id link
                const response = await fetch(
                    `${process.env.REACT_APP_SMARTFACE_BASE_SERVER}/devInit${window.document.location.search}`,
                    { credentials: 'include' }
                );
                const link = await response.text();
                // request to verify otp and get the index page
                const initRespose = await fetch(
                    `${process.env.REACT_APP_SMARTFACE_BASE_SERVER}${link}`,
                    { credentials: 'include' }
                );
                linkToIndex = await initRespose.text();
                // Perform actions for logged-in users
            }

            // request to get the index page variables
            const indexResponse = await fetch(
                `${process.env.REACT_APP_SMARTFACE_BASE_SERVER}${linkToIndex}`,
                { credentials: 'include' }
            );
            if (!indexResponse.ok) {
                // response.ok is true if status is in the range 200-299
                const errorJson = await indexResponse.json(); // You can customize this to include more info\
                window.SORTVARS = { error: errorJson };
                localStorage.setItem('isLoggedIn', 'false');
            } else {
                const data = await indexResponse.json();
                window.SORTVARS = data;
                localStorage.setItem('isLoggedIn', 'true');
            }

            ReactDOM.render(
                <React.StrictMode>
                    <DebugProvider
                        initialDebug={debug}
                        initialEnableError={enableError}
                    >
                        <App />
                    </DebugProvider>
                </React.StrictMode>,
                document.getElementById('root')
            );
        } catch (error) {
            console.log(error);
        }
    })();
} else {
    ReactDOM.render(
        <React.StrictMode>
            <DebugProvider
                initialDebug={debug}
                initialEnableError={enableError}
            >
                <App />
            </DebugProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// applyPolyfills().then(() => {
//     defineCustomElements(window);
// });
