import React from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import Button from '../../common/Button';
import Icon from '@ant-design/icons';
import { imgResultErrorSvg, imgResultSuccessSvg } from '../../common/images';
import TitleText from '../../common/TitleText';
import TypographyText from '../../common/TypographyText';

export default GwFlowValidateError;

export type GwFlowValidateErrorProps = {
    onFinishClick?: () => void;
    title?: string;
    subtitle?: string;
    buttonText?: string;
    supportLink?: string;
    transactionId?: string;
};

function GwFlowValidateError({
    onFinishClick,
    title,
    buttonText,
    subtitle,
    supportLink = 'https://css.goboarding.tech',
    transactionId,
}: GwFlowValidateErrorProps) {
    const { t } = useTranslation();

    return (
        <div className="GwFlowValidateError">
            <br />
            <br />
            <br />
            <br />
            <Icon component={imgResultErrorSvg} />
            <Space direction="vertical" size={20}>
                <TitleText size="large">
                    {t('¡No hemos podido validar tu identidad!')}
                </TitleText>
                <TitleText size="small">
                    {title
                        ? title
                        : t(
                              'Algo no salió bien durante el proceso de validación de identidad.'
                          )}
                    {supportLink ? (
                        <span>
                            {' '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'Por favor, <a target="_blank" href="{{supportLink}}?transactionId={{transactionId}}">comunícate con soporte</a> informando la siguiente referencia.',
                                        { supportLink, transactionId }
                                    ),
                                }}
                            />
                            <br />
                            <br />
                            <TypographyText
                                copyable={{
                                    text: `${transactionId}`,
                                }}
                            >
                                {t('Transacción')}: {transactionId}
                            </TypographyText>
                        </span>
                    ) : null}
                </TitleText>
                <Button type="primary" size="large" onClick={onFinishClick}>
                    {buttonText || t('Finalizar')}
                </Button>
            </Space>
        </div>
    );
}
