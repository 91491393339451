import { Typography as AntTypography } from 'antd';
import classNames from 'classnames';
import './TypographyText.css';
import { TextProps } from 'antd/es/typography/Text';

export default TypographyText;

export type TypographyTextProps = TextProps & {
    muted?: boolean;
    primary?: boolean;
};
function TypographyText({
    muted,
    primary,
    children,
    className,
    ...rest
}: TypographyTextProps) {
    return (
        <AntTypography.Text
            className={classNames(className, {
                'TypographyText--type-muted': muted,
                'TypographyText--type-primary': primary,
            })}
            {...rest}
        >
            {children}
        </AntTypography.Text>
    );
}
